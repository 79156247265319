import { baseEvaPage } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      tableData: [],
      pageInfo: {
        pageSize: 9,
        current: 1,
        total: 0,
        commentList: []
      }
    };
  },
  mounted() {
    this.getList();
    this.commentList = this.$root.commentList;
    this.imageSrc();
  },
  methods: {
    getList() {
      baseEvaPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    imageSrc() {
      //V3 动态引入图片
      for (let i in this.commentList) {
        import('@/static/img/eva/' + this.commentList[i].code + '.png').then(res => {
          this.commentList[i]['img'] = res.default;
        });
        import('@/static/img/eva/' + this.commentList[i].code + '_check.png').then(res => {
          this.commentList[i]['img_check'] = res.default;
        });
      }
    }
  }
};